// frontend/src/components/Home.tsx

import React, { useState, useCallback, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import { useEmployeeContext } from '../context/Mitarbeiter';
import { useAuthContext } from '../context/AuthContext';
// eslint-disable-next-line
import { Scanner } from '@yudiel/react-qr-scanner';
// Material-UI Komponenten
import {
  Container,
  Button,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  Snackbar,
  Alert,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from '@mui/material';
import { QrCodeScanner, Stop, Delete } from '@mui/icons-material';
import axios from 'axios';
import { Palette, PaletteArtikel } from '../types/api';

// Erweiterung der Palette-Schnittstelle für die UI
interface ScannedPalette {
  nve: string;
  palette?: Palette; // undefined für unbekannte Paletten
  isUnknown: boolean;
}

const Home: React.FC = () => {
  const { employees } = useEmployeeContext();
  const { selectedStoreId } = useAuthContext();
  const [Paletten, setPaletten] = useState<Palette[]>([]);
  const [showDialog, setShowDialog] = useState<boolean>(false);

  useEffect(() => {
    fetchPaletten();
  }, [selectedStoreId]);

  const fetchPaletten = async () => {
    if (!selectedStoreId) return;
    try {
      const today = new Date();
      let von, bis;

      von = today.toISOString().split('T')[0];
      bis = today.toISOString().split('T')[0];

      const response = await axios.get<Palette[]>(
        `/api/palettes/storeid/${selectedStoreId}?von=${von}&bis=${bis}`
      );
      if (response.data.length === 0) {
        console.log('Keine Paletten gefunden.');
        return;
      }
      let nicht_zugeordnet = response.data.filter(
        (palette) => !palette.mitarbeiter_nummer
      );
      console.log('Nicht zugewiesene Paletten:', nicht_zugeordnet);
      setPaletten(nicht_zugeordnet);
    } catch (error: any) {
      console.error('Fehler beim Abrufen der Paletten:', error);
    } finally {
      setLoading(false);
    }
  };

  // Zustände
  const [isScanning, setIsScanning] = useState(false);
  const [scannedPalettes, setScannedPalettes] = useState<ScannedPalette[]>([]);
  const [aggregatedData, setAggregatedData] = useState<{
    total_stueckzahl: number;
    total_anz_einheiten: number;
  }>({ total_stueckzahl: 0, total_anz_einheiten: 0 });
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'error' | 'warning' | 'info';
  }>({
    open: false,
    message: '',
    severity: 'success',
  });
  const [isProcessing, setIsProcessing] = useState(false); // Sperre für API-Aufrufe

  // Funktion zur Berechnung der Anzahl der Stücke
  const anz_Stueck = (Artikelliste: PaletteArtikel[]) => {
    return Artikelliste.reduce((sum, artikel) => sum + artikel.stueckzahl, 0);
  };

  // Handle Scan Callback
  const handleScan = useCallback(
    async (Ergebnis: any) => {
      if (!Ergebnis || Ergebnis.length === 0) return;

      let data = Ergebnis[0].rawValue;
      data = data.replace('800304010355672933', '');
      console.log('Gescanntes Ergebnis:', data);

      setIsScanning(false); // Stoppt den Scanner sofort

      // Überprüfen, ob die Palette bereits gescannt wurde
      const existingScanned = scannedPalettes.find((p) => p.nve === data);

      if (existingScanned) {
        if (!existingScanned.isUnknown && existingScanned.palette) {
          // Bekannte Palette erneut scannen: aggregiere die Daten
          const deltaStueckzahl = anz_Stueck(
            existingScanned.palette.artikelListe || []
          );
          const deltaAnzEinheiten = existingScanned.palette.artikelListe
            ? existingScanned.palette.artikelListe.reduce(
                (sum, artikel) => sum + (artikel.anz_einheiten || 0),
                0
              )
            : 0;

          setAggregatedData((prevAggregated) => ({
            total_stueckzahl:
              prevAggregated.total_stueckzahl + deltaStueckzahl,
            total_anz_einheiten:
              prevAggregated.total_anz_einheiten + deltaAnzEinheiten,
          }));

          setSnackbar({
            open: true,
            message: `Palette ${data} wurde erneut gescannt und aggregiert.`,
            severity: 'success',
          });
        } else {
          // Unbekannte Palette erneut scannen: zeigen Sie eine Warnung
          setSnackbar({
            open: true,
            message: `Unbekannte Palette ${data} wurde bereits gescannt.`,
            severity: 'warning',
          });
        }

        return;
      }

      // Palette ist noch nicht gescannt, versuche sie vom Backend zu holen
      try {
        setLoading(true);
        const response = await axios.get<Palette>(
          `/api/palettes/nve/${data}`
        );
        const fetchedPalette = response.data;

        // Füge die bekannte Palette hinzu
        setScannedPalettes((prevScanned) => [
          ...prevScanned,
          {
            nve: data,
            palette: fetchedPalette,
            isUnknown: false,
          },
        ]);

        // Aggregiere die Daten
        const deltaStueckzahl = anz_Stueck(fetchedPalette.artikelListe || []);
        const deltaAnzEinheiten = fetchedPalette.artikelListe
          ? fetchedPalette.artikelListe.reduce(
              (sum, artikel) => sum + (artikel.anz_einheiten || 0),
              0
            )
          : 0;

        setAggregatedData((prevAggregated) => ({
          total_stueckzahl:
            prevAggregated.total_stueckzahl + deltaStueckzahl,
          total_anz_einheiten:
            prevAggregated.total_anz_einheiten + deltaAnzEinheiten,
        }));

        setSnackbar({
          open: true,
          message: `Palette ${data} erfolgreich abgerufen und aggregiert.`,
          severity: 'success',
        });
      } catch (error: any) {
        if (
          axios.isAxiosError(error) &&
          error.response &&
          error.response.status === 404
        ) {
          // Palette existiert nicht, füge sie als unbekannte Palette hinzu
          const newScanned: ScannedPalette = {
            nve: data,
            palette: undefined,
            isUnknown: true,
          };

          setScannedPalettes((prevScanned) => [...prevScanned, newScanned]);

          setSnackbar({
            open: true,
            message: `Unbekannte Palette ${data} wurde hinzugefügt.`,
            severity: 'info',
          });
        } else {
          console.error('Fehler beim Abrufen der Palette:', error);
          setSnackbar({
            open: true,
            message:
              'Fehler beim Abrufen der Palette. Bitte versuchen Sie es erneut.',
            severity: 'error',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [scannedPalettes]
  );

  // Funktion zum Verarbeiten der ausgewählten Paletten aus Palettenauswahl
  const handlePalettesSelected = (palettes: Palette[]) => {
    // Setze scannedPalettes und aggregatedData zurück
    const newScannedPalettes: ScannedPalette[] = palettes.map((palette) => ({
      nve: palette.nve,
      palette: palette,
      isUnknown: false,
    }));
    setScannedPalettes(newScannedPalettes);

    // Berechne aggregierte Daten basierend auf den neuen Paletten
    const total_stueckzahl = palettes.reduce(
      (sum, palette) => sum + anz_Stueck(palette.artikelListe || []),
      0
    );
    const total_anz_einheiten = palettes.reduce(
      (sum, palette) =>
        sum +
        (palette.artikelListe
          ? palette.artikelListe.reduce(
              (sum, artikel) => sum + (artikel.anz_einheiten || 0),
              0
            )
          : 0),
      0
    );
    setAggregatedData({ total_stueckzahl, total_anz_einheiten });

    setSnackbar({
      open: true,
      message: `Paletten erfolgreich ausgewählt und aggregiert.`,
      severity: 'success',
    });
  };

  // Toggle Scanner starten/stoppen
  const toggleScanner = () => {
    setIsScanning((prev) => !prev);
  };

  const toggleDialog = () => {
    setShowDialog((prev) => !prev);
  };

  // Zuweisen von Paletten zu einem Mitarbeiter
  const assignPalettesToEmployee = useCallback(
    async (employeeId: string) => {
      if (scannedPalettes.length === 0 || isProcessing || !selectedStoreId)
        return;
      setIsProcessing(true);
      try {
        setLoading(true);
        const assignPromises = scannedPalettes.map((scanned) =>
          axios.put(`/api/palettes/${scanned.nve}`, {
            mitarbeiter_nummer: employeeId,
            storeId: selectedStoreId, // Include the selected StoreId
          })
        );
        await Promise.all(assignPromises);

        // Aktualisiere die Palette-Daten nach der Zuweisung
        const fetchPromises = scannedPalettes.map((scanned) =>
          axios.get<Palette>(`/api/palettes/nve/${scanned.nve}`)
        );
        const responses = await Promise.all(fetchPromises);
        const updatedPalettes = responses.map((res) => res.data);

        // Aktualisiere scannedPalettes mit den neuen Daten
        const updatedScannedPalettes = scannedPalettes.map(
          (scanned, index) => ({
            ...scanned,
            palette: updatedPalettes[index],
            isUnknown: updatedPalettes[index] ? false : true, // Aktualisiere den Status falls notwendig
          })
        );

        setScannedPalettes(updatedScannedPalettes);
        await fetchPaletten(); // Aktualisiere die Liste der nicht zugewiesenen Paletten
              // **Neuer Code zum Zurücksetzen der Zustände**
      setScannedPalettes([]); // Zurücksetzen der gescannten Paletten
      setAggregatedData({ total_stueckzahl: 0, total_anz_einheiten: 0 }); //

        setSnackbar({
          open: true,
          message: 'Paletten erfolgreich zugewiesen.',
          severity: 'success',
        });
      } catch (error: any) {
        console.error('Fehler beim Zuweisen der Paletten:', error);
        setSnackbar({
          open: true,
          message:
            'Fehler beim Zuweisen der Paletten. Bitte versuchen Sie es erneut.',
          severity: 'error',
        });
      } finally {
        fetchPaletten(); // Aktualisiere die Liste der nicht zugewiesenen Paletten
        setLoading(false);
        setIsProcessing(false);
      }
    },
    [scannedPalettes, isProcessing, selectedStoreId]
  );

  // Schließen der Snackbar
  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  // Funktion zum Hervorheben des zugewiesenen Mitarbeiters
  const isAssignedToEmployee = (employeeId: string) => {
    return scannedPalettes.some(
      (palette) => palette.palette?.mitarbeiter_nummer === employeeId
    );
  };

  // Entfernen einer gescannten Palette
  const removeScannedPalette = (nve: string) => {
    const paletteToRemove = scannedPalettes.find((p) => p.nve === nve);
    if (paletteToRemove && !paletteToRemove.isUnknown && paletteToRemove.palette) {
      // Wenn die Palette bekannt ist, subtrahiere die aggregierten Daten
      const deltaStueckzahl = anz_Stueck(
        paletteToRemove.palette.artikelListe || []
      );
      const deltaAnzEinheiten = paletteToRemove.palette.artikelListe
        ? paletteToRemove.palette.artikelListe.reduce(
            (sum, artikel) => sum + (artikel.anz_einheiten || 0),
            0
          )
        : 0;

      setAggregatedData((prevAggregated) => ({
        total_stueckzahl:
          prevAggregated.total_stueckzahl - deltaStueckzahl,
        total_anz_einheiten:
          prevAggregated.total_anz_einheiten - deltaAnzEinheiten,
      }));
    }

    setScannedPalettes((prevScanned) =>
      prevScanned.filter((item) => item.nve !== nve)
    );
  };

  return (
    <Container
      maxWidth="md"
      style={{ textAlign: 'center', padding: '20px' }}
    >
      <Button
        onClick={() => {
          toggleDialog();
        }}
      >
        Paletten anzeigen
      </Button>

      {showDialog && (
        <Palettenauswahl
          vissible={showDialog}
          closeDialog={toggleDialog}
          paletten={Paletten}
          onPalettesSelected={handlePalettesSelected}
        />
      )}

      {Paletten.length > 0 && (
        <Box marginTop={4}>
          {Paletten.filter((palette) =>
            palette.nve.startsWith('00343')
          ).length > 0 && (
            <Typography variant="h6" gutterBottom>
              Nicht zugewiesene Paletten:{' '}
              {
                Paletten.filter((palette) =>
                  palette.nve.startsWith('00343')
                ).length
              }
            </Typography>
          )}
          {Paletten.filter(
            (palette) => !palette.nve.startsWith('00343')
          ).length > 0 && (
            <Typography variant="h6" gutterBottom>
              Nicht zugewiesene KTB :{' '}
              {
                Paletten.filter(
                  (palette) => !palette.nve.startsWith('00343')
                ).length
              }
            </Typography>
          )}
        </Box>
      )}
      <Box marginBottom={2}>
        <Button
          variant="contained"
          color={isScanning ? 'secondary' : 'primary'}
          startIcon={isScanning ? <Stop /> : <QrCodeScanner />}
          onClick={toggleScanner}
        >
          {isScanning ? 'Scanner stoppen' : 'Scanner starten'}
        </Button>
      </Box>

      {isScanning && (
        <Box marginBottom={2}>
          <Scanner

            paused={!isScanning}
            onScan={handleScan}
            formats={['ean_13', 'code_128']}
          />
        </Box>
      )}
      {loading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop={2}
        >
          <CircularProgress />
        </Box>
      )}
          <Box marginTop={4}> 
            <Typography variant="body1">
              Gesamt Anzahl DANs: {aggregatedData.total_anz_einheiten}
            </Typography>
            <Typography variant="body1">
              Gesamt Anzahl Stück: {aggregatedData.total_stueckzahl}
            </Typography>
           
          </Box>      
      {scannedPalettes.length > 0 && (
        <Box marginTop={4}>
          <Typography variant="h6" gutterBottom>
            Gescannte Paletten:
          </Typography>
          <List>
            {scannedPalettes.map((scanned) => (
              <ListItem
                key={scanned.nve}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => removeScannedPalette(scanned.nve)}
                  >
                    <Delete />
                  </IconButton>
                }
              >
                <ListItemText
                  primary={`NVE: ${scanned.nve}`}
                  secondary={
                    scanned.isUnknown || !scanned.palette ? (
                      'Unbekannte Palette.'
                    ) : (
                      <>
                        <Typography variant="body2">
                          Zugewiesen an:{' '}
                          {
                            employees.find(
                              (emp) =>
                                emp.id ===
                                scanned.palette?.mitarbeiter_nummer
                            )?.vorname
                          }{' '}
                          {
                            employees.find(
                              (emp) =>
                                emp.id ===
                                scanned.palette?.mitarbeiter_nummer
                            )?.name
                          }
                        </Typography>
                      </>
                    )
                  }
                />
              </ListItem>
            ))}
          </List>

          <Box marginTop={2}>
            <Grid container spacing={2}>
              {employees
                .filter((emp) =>
                  emp.storeIds.includes(selectedStoreId || '')
                ) // Filter employees by selected StoreId
                .map((employee) => (
                  <Grid item xs={12} sm={6} md={4} key={employee.id}>
                    <Card
                      variant="outlined"
                      sx={{
                        borderColor: isAssignedToEmployee(employee.id)
                          ? 'primary.main'
                          : 'grey.300',
                        backgroundColor: isAssignedToEmployee(employee.id)
                          ? 'primary.light'
                          : 'background.paper',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        assignPalettesToEmployee(employee.id)
                      }
                    >
                      <CardActionArea>
                        <CardContent>
                          <Typography variant="h6" component="div">
                            {employee.vorname} {employee.name}
                          </Typography>
                          {isAssignedToEmployee(employee.id) && (
                            <Typography
                              variant="body2"
                              color="textSecondary"
                            >
                              Zugewiesen
                            </Typography>
                          )}
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          </Box>
        </Box>
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

interface PalettenauswahlProps {
  closeDialog?: () => void;
  vissible: boolean; // Jetzt erforderlich
  paletten?: Palette[];
  onPalettesSelected?: (paletten: Palette[]) => void;
  storeID?: string;
}

const Palettenauswahl: React.FC<PalettenauswahlProps> = (props) => {
  const [palettenÜberischt] = useState<Palette[]>(props.paletten || []);
  const [selPalette, setSelPalette] = useState<Palette[]>([]);

  // Funktion zum Auswählen einer Palette
  const handleSelection = (selectedPalette: Palette) => {
    console.log('Ausgewählte Palette:', selectedPalette);
    // wenn die gleiche Palette bereits ausgewählt wurde, entferne sie

    if (selPalette.some((palette) => palette.nve === selectedPalette.nve)) {
      setSelPalette((prev) =>
        prev.filter((palette) => palette.nve !== selectedPalette.nve)
      );
      return;
    }
    //
    setSelPalette((prev) => [...prev, selectedPalette]);
  };

  const handleClose = () => {
    if (props.onPalettesSelected) {
      props.onPalettesSelected(selPalette);
    }
    if (props.closeDialog) {
      props.closeDialog();
    }
  };

  return (
    <>
      <Dialog
        open={props.vissible}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        {/* Dialog-Titel */}
        <DialogTitle>Palettenauswahl</DialogTitle>

        {/* Dialog-Inhalt mit fixiertem Header */}
        <DialogContent dividers>
          {/* Fixierter Header */}
          <Box mb={2}>
            <Typography variant="h6" gutterBottom>
              Anzahl DANs:{' '}
              {selPalette.reduce(
                (sum, palette) => sum + (palette.artikelListe?.length || 0),
                0
              )}
            </Typography>
            <Typography variant="h6" gutterBottom>
              Anzahl Stück:{' '}
              {selPalette.reduce(
                (sum, palette) =>
                  sum +
                  (palette.artikelListe?.reduce(
                    (sum, artikel) => sum + artikel.stueckzahl,
                    0
                  ) || 0),
                0
              )}
            </Typography>
          </Box>

          {/* Scrollbarer Inhalt */}
          <Box
            maxHeight="400px" // Passe die Höhe nach Bedarf an
            overflow="auto"
          >
            <List>
              {palettenÜberischt &&
                palettenÜberischt.map((palette) => (
                  <ListItem
                    key={palette.nve}
                    onClick={() => handleSelection(palette)}
                    sx={{
                      backgroundColor: selPalette.some(
                        (p) => p.nve === palette.nve
                      )
                        ? 'primary.light'
                        : 'inherit',
                      cursor: 'pointer',
                    }}
                  >
                    <ListItemText
                      primary={`NVE: ${palette.nve}`}
                      secondary={`Anzahl Artikel: ${
                        palette.artikelListe?.length || 0
                      }`}
                    />
                  </ListItem>
                ))}
            </List>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Home;
