// frontend/src/components/Login.tsx

import React, { useState } from 'react';
import { useAuthContext } from '../context/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Alert,
} from '@mui/material';

const Login: React.FC = () => {
  const { login } = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [credentials, setCredentials] = useState({ id: '', password: '' });
  const [error, setError] = useState<string | null>(null);

  const from = (location.state as any)?.from?.pathname || '/';

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      await login(credentials);
      navigate(from, { replace: true });
    } catch (err: any) {
      setError('Anmeldung fehlgeschlagen. Bitte überprüfen Sie Ihre ID und Ihr Passwort.');
    }
  };

  return (
    <Container maxWidth="sm">
      <Box mt={8}>
        <Typography variant="h4" gutterBottom>
          Anmeldung
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
        <form onSubmit={handleSubmit}>
          <TextField
            label="ID"
            name="id"
            fullWidth
            margin="normal"
            value={credentials.id}
            onChange={(e) => setCredentials({ ...credentials, id: e.target.value })}
          />
          <TextField
            label="Passwort"
            name="password"
            type="password"
            fullWidth
            margin="normal"
            value={credentials.password}
            onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
          />
          <Box mt={2}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Anmelden
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default Login;
