// frontend/src/components/Navigation.tsx

import React from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Hidden,
  ListItemButton,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useMediaQuery,
  SelectChangeEvent,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useLocation } from 'react-router-dom';
import { useAuthContext } from '../context/AuthContext';
import { useTheme } from '@mui/material/styles';


const navItems = [
  { text: 'Palettenannahme', path: '/' },
  { text: 'Palettenübersicht', path: '/palettes' },
  { text: 'Prozesse', path: '/processes' },
  { text: 'Mitarbeiter', path: '/employees' },
  { text: 'Import', path: '/import' },
];

const Navigation: React.FC = () => {

const theme = useTheme();
const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const { user, logout, selectedStoreId, setSelectedStoreId } = useAuthContext();
  const location = useLocation();

  const handleLogout = async () => {
    await logout();
  };

  const handleStoreChange = (event: SelectChangeEvent<string>) => {
    setSelectedStoreId(event.target.value as string);
  };

  const drawer = (
    <div>
      <Toolbar />
      <List>
        {
        navItems.filter((item) => !(isMobile && item.text === 'Import'))
        .map((item) => (
          item.text === 'Mitarbeiter' && !user?.can_edit ? null : (
            <ListItem key={item.text} disablePadding>
              <ListItemButton
                component={Link}
                to={item.path}
                onClick={() => setMobileOpen(false)}
                selected={location.pathname === item.path}
              >
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          )
        ))}
        {user && (
          <ListItem disablePadding>
            <ListItemButton onClick={handleLogout}>
              <ListItemText primary="Abmelden" />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </div>
  );

  return (
    <>
      <AppBar position="fixed">
        <Toolbar style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 16, paddingBottom: 10 }}>
          <Hidden mdUp>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
            {user && (
              <FormControl variant="outlined" style={{ minWidth: 120, marginLeft: 20 }}>
                <InputLabel id="mobile-store-select-label">Store</InputLabel>
                <Select
                  labelId="mobile-store-select-label"
                  value={selectedStoreId || ''}
                  onChange={handleStoreChange}
                  label="Store"
                  style={{ color: 'white' }}
                >
                  {user.storeIds.map((storeId) => (
                    <MenuItem key={storeId} value={storeId}>
                      {storeId}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Hidden>
          <Hidden mdDown>
            {user && (
              <FormControl variant="outlined" style={{ minWidth: 120, marginRight: 20 }}>
                <InputLabel id="store-select-label">Store</InputLabel>
                <Select
                  labelId="store-select-label"
                  value={selectedStoreId || ''}
                  onChange={handleStoreChange}
                  label="Store"
                  style={{ color: 'white' }}
                >
                  {user.storeIds.map((storeId) => (
                    <MenuItem key={storeId} value={storeId}>
                      {storeId}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
              {navItems.map((item) => (
                item.text === 'Mitarbeiter' && !user?.can_edit ? null : (
                  <Button
                    key={item.text}
                    component={Link}
                    to={item.path}
                    color="inherit"
                    style={{
                      textDecoration: 'none',
                      marginLeft: 20,
                      borderBottom: location.pathname === item.path ? '2px solid #fff' : 'none',
                    }}
                  >
                    {item.text}
                  </Button>
                )
              ))}
              {user && (
                <Button color="inherit" onClick={handleLogout} style={{ marginLeft: 20 }}>
                  Abmelden
                </Button>
              )}
            </div>
          </Hidden>
        </Toolbar>
      </AppBar>
      <Toolbar /> {/* Um Inhalte unterhalb der AppBar zu schieben */}
      <nav>
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Bessere Öffnungsleistung auf Mobilgeräten
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </>
  );
};

export default Navigation;