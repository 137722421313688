import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../context/AuthContext';
import axios from 'axios';
import {
  Container,
  Typography,
  Box,
  CircularProgress,
  Grid,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { Palette } from '../types/api';
import { useEmployeeContext } from '../context/Mitarbeiter';

type TimeRange = 'today' | 'last7Days' | 'last30Days';

const HeuteErfasstePaletten: React.FC = () => {
  const { selectedStoreId, user } = useAuthContext();
  const { employees } = useEmployeeContext();
  const [paletten, setPaletten] = useState<Palette[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [timeRange, setTimeRange] = useState<TimeRange>('today');

  useEffect(() => {
    const fetchPaletten = async () => {
      if (!selectedStoreId) return;
      setLoading(true);
      try {
        const today = new Date();
        let von, bis;

        switch (timeRange) {
          case 'last7Days':
            const sevenDaysAgo = new Date(today);
            sevenDaysAgo.setDate(today.getDate() - 7);
            von = sevenDaysAgo.toISOString().split('T')[0];
            bis = today.toISOString().split('T')[0];
            break;
          case 'last30Days':
            const thirtyDaysAgo = new Date(today);
            thirtyDaysAgo.setDate(today.getDate() - 30);
            von = thirtyDaysAgo.toISOString().split('T')[0];
            bis = today.toISOString().split('T')[0];
            break;
          case 'today':
          default:
            von = today.toISOString().split('T')[0];
            bis = today.toISOString().split('T')[0];
            break;
        }

        const response = await axios.get<Palette[]>(
          `/api/palettes/storeid/${selectedStoreId}?von=${von}&bis=${bis}`
        );
        setPaletten(response.data);
      } catch (error) {
        console.error('Fehler beim Abrufen der Paletten:', error);
        setPaletten([]);
      } finally {
        setLoading(false);
      }
    };

    fetchPaletten();
  }, [selectedStoreId, timeRange]);

  const handleTimeRangeChange = (event: SelectChangeEvent<TimeRange>) => {
    setTimeRange(event.target.value as TimeRange);
  };

  const formatDateToGerman = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('de-DE');
  };

  return (
    <Container maxWidth="md" style={{ textAlign: 'center', padding: '20px' }}>

      <FormControl variant="outlined" fullWidth style={{ marginBottom: '20px' }}>
        <InputLabel id="time-range-select-label">Zeitraum</InputLabel>
        <Select
          labelId="time-range-select-label"
          value={timeRange}
          onChange={handleTimeRangeChange}
          label="Zeitraum"
        >
          <MenuItem value="today">Heute</MenuItem>
          <MenuItem value="last7Days">Letzte 7 Tage</MenuItem>
          <MenuItem value="last30Days">Letzte 30 Tage</MenuItem>
        </Select>
      </FormControl>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" marginTop={2}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2}>
          {paletten.length === 0 ? (
            <Typography variant="subtitle1" color="textSecondary">
              Keine Paletten im ausgewählten Zeitraum gefunden.
            </Typography>
          ) : (
            paletten.map((palette) => (
              <Grid item xs={12} sm={6} md={4} key={palette.nve}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="h6">{palette.nve}</Typography>
                    <Typography variant="body2">
                    verräumt von: {palette.mitarbeiter_nummer ? employees.find((emp) => emp.id === palette.mitarbeiter_nummer)?.vorname + ' ' + employees.find((emp) => emp.id === palette.mitarbeiter_nummer)?.name : 'Noch nicht zugewiesen'}
                    </Typography>
                    <Typography variant="body2">verräumt am: {formatDateToGerman(palette.datum)}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))
          )}
        </Grid>
      )}
    </Container>
  );
};

export default HeuteErfasstePaletten;