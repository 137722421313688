// frontend/src/components/import.tsx

import React, { useState } from 'react';
import { useAuthContext } from '../context/AuthContext';
import {
  Container,
  Typography,
  Box,
  Button,
  TextField,
  Snackbar,
  Alert,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { UploadFile, InsertDriveFile } from '@mui/icons-material';
import axios from 'axios';

const Import: React.FC = () => {
  const { selectedStoreId } = useAuthContext();
  const [weDatum, setWeDatum] = useState<string>('');
  const [files, setFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'error' | 'warning';
  }>({
    open: false,
    message: '',
    severity: 'success',
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFiles = Array.from(e.target.files);
      const validFiles: File[] = [];
      const invalidFiles: string[] = [];

      selectedFiles.forEach((file) => {
        if (
          file.type ===
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
          file.name.endsWith('.xlsx')
        ) {
          validFiles.push(file);
        } else {
          invalidFiles.push(file.name);
        }
      });

      if (invalidFiles.length > 0) {
        setSnackbar({
          open: true,
          message: `Ungültige Dateien ignoriert: ${invalidFiles.join(', ')}`,
          severity: 'error',
        });
      }

      if (validFiles.length > 0) {
        setFiles((prevFiles) => [...prevFiles, ...validFiles]);
      }
    }
  };

  const handleRemoveFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (files.length === 0) {
      setSnackbar({
        open: true,
        message: 'Bitte wählen Sie mindestens eine XLSX-Datei aus.',
        severity: 'warning',
      });
      return;
    }

    if (!weDatum) {
      setSnackbar({
        open: true,
        message: 'Bitte wählen Sie ein Datum aus.',
        severity: 'warning',
      });
      return;
    }

    if (!selectedStoreId) {
      setSnackbar({
        open: true,
        message: 'Store ID ist nicht ausgewählt.',
        severity: 'error',
      });
      return;
    }

    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });
    formData.append('we_datum', weDatum);
    formData.append('storeId', selectedStoreId);

    try {
      setLoading(true);
      const response = await axios.post('/api/import/palettes', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setSnackbar({
        open: true,
        message: response.data.message || 'Dateien erfolgreich importiert.',
        severity: 'success',
      });
      // Zurücksetzen des Formulars
      setFiles([]);
      setWeDatum('');
    } catch (error: any) {
      console.error('Fehler beim Importieren der Dateien:', error);
      setSnackbar({
        open: true,
        message:
          error.response?.data?.error ||
          'Fehler beim Importieren der Dateien. Bitte versuchen Sie es erneut.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <Container maxWidth="sm" style={{ paddingTop: '20px', textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>
        Paletten-Import
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          label="Datum der Wareneingangsannahme"
          type="date"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          value={weDatum}
          onChange={(e) => setWeDatum(e.target.value)}
          margin="normal"
          required
        />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          marginTop={2}
          marginBottom={2}
        >
          <input
            accept=".xlsx"
            style={{ display: 'none' }}
            id="upload-files"
            type="file"
            multiple
            onChange={handleFileChange}
          />
          <label htmlFor="upload-files">
            <Button
              variant="contained"
              color="primary"
              component="span"
              startIcon={<UploadFile />}
            >
              Dateien auswählen
            </Button>
          </label>
          {files.length > 0 && (
            <List style={{ width: '100%', marginTop: '10px' }}>
              {files.map((file, index) => (
                <ListItem
                  key={index}
                  secondaryAction={
                    <Button
                      color="error"
                      onClick={() => handleRemoveFile(index)}
                    >
                      Entfernen
                    </Button>
                  }
                >
                  <ListItemIcon>
                    <InsertDriveFile />
                  </ListItemIcon>
                  <ListItemText primary={file.name} />
                </ListItem>
              ))}
            </List>
          )}
        </Box>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          fullWidth
          disabled={loading}
          startIcon={loading && <CircularProgress size={20} />}
        >
          {loading ? 'Importieren...' : 'Importieren'}
        </Button>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Import;
