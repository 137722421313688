import React, { useEffect, useRef, useState } from 'react';
import { Html5Qrcode, Html5QrcodeResult } from 'html5-qrcode';

interface BarcodeScannerProps {
  onDetected: (result: string) => void;
}

const BarcodeScanner: React.FC<BarcodeScannerProps> = ({ onDetected }) => {
  const scannerRef = useRef<HTMLDivElement | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isScanning, setIsScanning] = useState<boolean>(false);
  const html5QrCodeRef = useRef<Html5Qrcode | null>(null);

  useEffect(() => {
    if (scannerRef.current && !html5QrCodeRef.current) {
      const html5QrCode = new Html5Qrcode(scannerRef.current.id);
      html5QrCodeRef.current = html5QrCode;

      const config = {
        fps: 10,
        qrbox: { width: 250, height: 250 },
        aspectRatio: 1.7777777778,
        disableFlip: false,
        rememberLastUsedCamera: true,
      };

      const startScanner = async () => {
        try {
          await html5QrCode.start(
            { facingMode: 'environment' },
            config,
            (decodedText: string, decodedResult: Html5QrcodeResult) => {
              onDetected(decodedText);
              stopScanning();
            },
            (errorMessage: string) => {
              
            }
          );
          setIsScanning(true);
        } catch (err: any) {
          console.error('Unable to start scanning:', err);
          setError('Kamera konnte nicht gestartet werden.');
        }
      };

      startScanner();

      return () => {
        stopScanning();
      };
    }
  }, [onDetected]);

  const stopScanning = async () => {
    if (html5QrCodeRef.current) {
      try {
        await html5QrCodeRef.current.stop();
        await html5QrCodeRef.current.clear();
        setIsScanning(false);
        html5QrCodeRef.current = null; // Referenz zurücksetzen
      } catch (err: any) {
        console.error('Error stopping scanner:', err);
        setError('Fehler beim Stoppen der Kamera.');
      }
    }
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      {error && <p style={{ color: 'red' }}>Fehler: {error}</p>}
      <div id="scanner-container" ref={scannerRef} style={{ width: '100%', height: '100%' }}></div>
    </div>
  );
};

export default BarcodeScanner;
