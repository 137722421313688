// frontend/src/types/api.ts

// Enums
export enum Artikel_Prozess {
    Nicht_gefunden = "Nicht gefunden",
    Fehlerhaft_verräumt = "Fehlerhaft verräumt",
    Falscher_Bestand = "Falscher Bestand",
    Unzureichend_Geatmet = "Unzureichend Geatmet",
    Nicht_Bedarfsgerecht = "Nicht Bedarfsgerecht",
}

export enum Geatmet {
    Gleicher_Boden = "Gleicher Boden",
    Angrenzender_Boden = "Angrenzender Boden",
    Gleicher_Meter = "Gleicher Meter",
    sonstiges = "sonstiges",
}

export enum Zeitbedarf_Prozess {
    Minute_1 = "1 Minute",
    Minuten_5 = "5 Minuten",
    Minuten_10 = "10 Minuten",
}

// Interfaces

// Prozess Interface
export interface Prozess {
    uuid: string;
    gtin: string | null;
    zeitbedarf: Zeitbedarf_Prozess | null;
    rechnerischer_bestand: number | null;
    alter_restbestand: number | null;
    neuer_restbestand: number | null;
    has_photo: boolean;
    is_closed: boolean;
    has_error: boolean;
    artikel_prozess: Artikel_Prozess | null;
    geatmet?: Geatmet | null;
    datum: string | null;
    store_id: string | null;
    palette_nve: string | null;
    zusatzliche_informationen?: string | "";
    mitarbeiter_nummer?: string | "";
    verraeumende_mitarbeiter_nummer?: string | null; // Neues Feld
}

// Artikel (Article)
export interface Artikel {
    name: string;
    hersteller: string;
    gtin: string;
    dan: string;
    Bestand?: number;
}

// Mitarbeiter (Employee)
export interface Mitarbeiter {
    id: string;
    vorname: string;
    name: string;
    email?: string;
    storeIds: string[];
    can_edit: boolean;
    admin: boolean;
    super_admin: boolean;
}

// Store
export interface Store {
    id: string;
}

// PalettenDetails
export interface PalettenDetails {
    datum: string;
    storeid: string;
    // Weitere Felder nach Bedarf
}

// PaletteArtikel
export interface PaletteArtikel {
    artikel: Artikel;
    anz_einheiten: number;
    stueckzahl: number;
}

// Palette
export interface Palette {
    nve: string;
    storeId: string;
    datum: string;
    mitarbeiter_nummer?: string;
    artikelListe?: PaletteArtikel[];
    verraeumzeit?: number;
    details?: PalettenDetails[];
}

// Gemeinsame Antworttypen
export interface ErrorResponse {
    error: string;
}

export interface MessageResponse {
    message: string;
}

// API Endpunkt Typen

// Articles API (/api/articles)

// GET /api/articles
export type GetArticlesResponse = Artikel[];

// GET /api/articles/:gtin
export type GetArticleResponse = Artikel;

// DELETE /api/articles/:gtin
export interface DeleteArticleResponse {
    message: string;
    artikel: Artikel;
}

// Employees API (/api/employees)

// GET /api/employees?storeId=...
export type GetEmployeesResponse = Mitarbeiter[];

// GET /api/employees/:id
export type GetEmployeeResponse = Mitarbeiter;

// POST /api/employees
export interface CreateEmployeeRequest {
    mitarbeiter: Mitarbeiter;
    password: string;
    webAuthnKey?: string | null;
}
export type CreateEmployeeResponse = MessageResponse;

// PUT /api/employees/:id
export interface UpdateEmployeeRequest {
    currentUser: Mitarbeiter;
    mitarbeiter: Mitarbeiter;
    newPassword?: string;
    newWebAuthnKey?: string | null;
}
export type UpdateEmployeeResponse = MessageResponse;

// DELETE /api/employees/:id
export interface DeleteEmployeeRequest {
    currentUser: Mitarbeiter;
}
export type DeleteEmployeeResponse = MessageResponse;

// Import API (/api/import)

// POST /api/import/palettes
export interface ImportPalettesRequest {
    file: File;
}
export type ImportPalettesResponse = MessageResponse;

// Palettes API (/api/palettes)

// GET /api/palettes/storeid/:StoreID?von=...&bis=...
export type GetPalettesResponse = Palette[];

// GET /api/palettes/nve/:nve
export type GetPaletteResponse = Palette;

// GET /api/palettes/employee/:mitarbeiterId
export type GetPalettesByEmployeeResponse = Palette[];

// GET /api/palettes/date-range?von=...&bis=...
export type GetPalettesByDateRangeResponse = Palette[];

// POST /api/palettes
export interface CreatePaletteRequest {
    palette: Palette;
}
export type CreatePaletteResponse = MessageResponse;

// PUT /api/palettes/:nve
export interface UpdatePaletteRequest {
    paletteUpdates: Partial<Palette>;
}
export type UpdatePaletteResponse = MessageResponse;

// DELETE /api/palettes/:nve
export type DeletePaletteResponse = MessageResponse;

// Processes API (/api/processes)

// GET /api/processes?von=...&bis=...&storeId=...&status=...&errorStatus=...
export type GetProcessesResponse = Prozess[];

// GET /api/processes/:uuid
export type GetProcessResponse = Prozess;

// POST /api/processes
export interface CreateProcessRequest {
    prozessData: Omit<
        Prozess,
        'uuid' | 'palette_nve' | 'has_photo' | 'is_closed' | 'has_error'
    >;
    foto?: File;
}
export interface CreateProcessResponse {
    message: string;
    prozess: Prozess;
}

// DELETE /api/processes/:uuid
export type DeleteProcessResponse = MessageResponse;

// POST /api/processes/:uuid/close
export type CloseProcessResponse = MessageResponse;

// POST /api/processes/:uuid/reopen
export type ReopenProcessResponse = MessageResponse;

// POST /api/processes/:uuid/mark-error
export type MarkProcessErrorResponse = MessageResponse;

// POST /api/processes/:uuid/reset-error
export type ResetProcessErrorResponse = MessageResponse;

// PUT /api/processes/:uuid - Einen Prozess aktualisieren
export interface UpdateProcessRequest {
    prozessData: Partial<Omit<Prozess, 'uuid' | 'palette_nve' | 'has_photo' | 'is_closed' | 'has_error'>>;
    closure_type?: 'employee_error' | 'inventory_error'; // Neues Feld zur Spezifizierung der Abschlussart
    foto?: File;
}
export interface UpdateProcessResponse {
    message: string;
    prozess: Prozess;
}
