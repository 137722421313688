// frontend/src/components/Mitarbeiter.tsx

import React, { useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    useMediaQuery,
    useTheme,
    Grid,
    Chip,
    FormControlLabel,
    Checkbox,
    Tooltip,
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { useEmployeeContext } from '../context/Mitarbeiter';
import { useAuthContext } from '../context/AuthContext';
import { Mitarbeiter, CreateEmployeeRequest, UpdateEmployeeRequest, MessageResponse } from '../types/api';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

const MitarbeiterComponent: React.FC = () => {
    const { fetchEmployees , employees, addEmployee, deleteEmployee } = useEmployeeContext();
    const { user, selectedStoreId } = useAuthContext();
    const [open, setOpen] = useState(false); // Für Add Dialog
    const [editOpen, setEditOpen] = useState(false); // Für Edit Dialog
    const [currentEditEmployee, setCurrentEditEmployee] = useState<Mitarbeiter | null>(null);

    const [newEmployee, setNewEmployee] = useState<Partial<Mitarbeiter>>({
        id: '',
        vorname: '',
        name: '',
        email: '',
        storeIds: [],
        can_edit: false,
        admin: false,
        super_admin: false,
    });
    const [storeIdInput, setStoreIdInput] = useState('');
    const [storeIdError, setStoreIdError] = useState('');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [idError, setIdError] = useState('');
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    // Bestimmen, welche Berechtigungen der aktuelle Benutzer vergeben kann
    const getAssignablePermissions = () => {
        if (user?.super_admin) {
            return {
                can_edit: true,
                admin: true,
                super_admin: true,
            };
        } else if (user?.admin) {
            return {
                can_edit: true,
                admin: false,
                super_admin: false,
            };
        } else {
            return {
                can_edit: false,
                admin: false,
                super_admin: false,
            };
        }
    };

    const assignablePermissions = getAssignablePermissions();

    // Funktion zum Öffnen des Hinzufügen-Dialogs
    const handleClickOpen = () => {
        setOpen(true);
        if (selectedStoreId) {
            setNewEmployee((prev) => ({
                ...prev,
                storeIds: [selectedStoreId],
            }));
            setStoreIdInput(''); // Stelle sicher, dass das Eingabefeld leer ist
            setStoreIdError('');
        }
    };

    // Funktion zum Schließen des Hinzufügen-Dialogs
    const handleClose = () => {
        fetchEmployees(); // Aktualisiere die Mitarbeiterliste nach dem Schließen des Dialogs
        setOpen(false);
        setNewEmployee({
            id: '',
            vorname: '',
            name: '',
            email: '',
            storeIds: [],
            can_edit: false,
            admin: false,
            super_admin: false,
        });
        setStoreIdInput('');
        setStoreIdError('');
        setPassword('');
        setPasswordError('');
        setIdError('');
    };

    // Funktion zum Schließen des Bearbeiten-Dialogs
    const handleEditClose = () => {
        setEditOpen(false);
        setCurrentEditEmployee(null);
        setPassword('');
        setPasswordError('');
    };

    // Funktion zum Handhaben von Eingabeänderungen im Hinzufügen-Dialog
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        setNewEmployee((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    // Funktion zum Handhaben von Eingabeänderungen im Bearbeiten-Dialog
    const handleEditChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!currentEditEmployee) return;
        const { name, value, type, checked } = e.target;
        setCurrentEditEmployee({
            ...currentEditEmployee,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    // Funktion zum Hinzufügen einer Store-ID im Hinzufügen-Dialog
    const handleAddStoreId = () => {
        const storeIdPattern = /^D[A-Z0-9]{3}$/;
        const upperStoreId = storeIdInput.toUpperCase();
        if (!storeIdPattern.test(upperStoreId)) {
            setStoreIdError('Store ID muss mit "D" beginnen, gefolgt von 3 Großbuchstaben oder Zahlen.');
            return;
        }
        if (newEmployee.storeIds && newEmployee.storeIds.includes(upperStoreId)) {
            setStoreIdError('Store ID bereits hinzugefügt.');
            return;
        }
        if (upperStoreId === selectedStoreId) {
            setStoreIdError('Die aktuelle Store ID ist bereits hinzugefügt.');
            return;
        }
        setNewEmployee((prev) => ({
            ...prev,
            storeIds: [...(prev.storeIds || []), upperStoreId],
        }));
        setStoreIdInput('');
        setStoreIdError('');
    };

    // Funktion zum Hinzufügen einer Store-ID im Bearbeiten-Dialog
    const handleEditAddStoreId = () => {
        if (!currentEditEmployee) return;
        const storeIdPattern = /^D[A-Z0-9]{3}$/;
        const upperStoreId = storeIdInput.toUpperCase();
        if (!storeIdPattern.test(upperStoreId)) {
            setStoreIdError('Store ID muss mit "D" beginnen, gefolgt von 3 Großbuchstaben oder Zahlen.');
            return;
        }
        if (currentEditEmployee.storeIds.includes(upperStoreId)) {
            setStoreIdError('Store ID bereits hinzugefügt.');
            return;
        }
        if (upperStoreId === selectedStoreId) {
            setStoreIdError('Die aktuelle Store ID ist bereits hinzugefügt.');
            return;
        }
        setCurrentEditEmployee({
            ...currentEditEmployee,
            storeIds: [...currentEditEmployee.storeIds, upperStoreId],
        });
        setStoreIdInput('');
        setStoreIdError('');
    };

    // Funktion zum Entfernen einer Store-ID im Hinzufügen-Dialog
    const handleRemoveStoreId = (storeId: string) => {
        if (storeId === selectedStoreId) {
            setStoreIdError('Die aktuelle Store ID kann nicht entfernt werden.');
            return;
        }
        setNewEmployee((prev) => ({
            ...prev,
            storeIds: prev.storeIds?.filter((id) => id !== storeId),
        }));
    };

    // Funktion zum Entfernen einer Store-ID im Bearbeiten-Dialog
    const handleEditRemoveStoreId = (storeId: string) => {
        if (!currentEditEmployee) return;
        if (storeId === selectedStoreId) {
            alert('Die aktuelle Store ID kann nicht entfernt werden.');
            return;
        }
        setCurrentEditEmployee({
            ...currentEditEmployee,
            storeIds: currentEditEmployee.storeIds.filter((id) => id !== storeId),
        });
    };

    // Lokale Update-Funktion, da sie nicht im Kontext vorhanden ist
    const handleUpdateEmployee = async () => {
        if (!currentEditEmployee) return;

        // Überprüfen, ob der bearbeitete Mitarbeiter der aktuelle Benutzer ist
        const isEditingSelf = currentEditEmployee.id === user?.id;

        // Validierungen
        if (!currentEditEmployee.vorname || !currentEditEmployee.name) {
            alert('Vorname und Name sind erforderlich.');
            return;
        }
        if (!currentEditEmployee.storeIds || currentEditEmployee.storeIds.length === 0) {
            alert('Mindestens eine Store ID ist erforderlich.');
            return;
        }

        // Hierarchie-Prüfung im Frontend
        if (currentEditEmployee.super_admin && !user?.super_admin) {
            alert('Nur Super-Admins können Super-Admins bearbeiten.');
            return;
        }
        if (currentEditEmployee.admin && !(user?.admin || user?.super_admin)) {
            alert('Nur Admins oder Super-Admins können Admins bearbeiten.');
            return;
        }
        if (currentEditEmployee.can_edit && !(user?.can_edit || user?.admin || user?.super_admin)) {
            alert('Unzureichende Berechtigungen zum Bearbeiten von can_edit Benutzern.');
            return;
        }

        // Sicherstellen, dass der Benutzer nicht höhere Berechtigungen zuweist als erlaubt
        if (currentEditEmployee.super_admin && !assignablePermissions.super_admin) {
            alert('Sie haben keine Berechtigung, Super Admins zuzuweisen.');
            return;
        }
        if (currentEditEmployee.admin && !assignablePermissions.admin) {
            alert('Sie haben keine Berechtigung, Admins zuzuweisen.');
            return;
        }
        if (currentEditEmployee.can_edit && !assignablePermissions.can_edit) {
            alert('Sie haben keine Berechtigung, can_edit Benutzer zuzuweisen.');
            return;
        }

        // Optional: Passwortänderung
        let finalPassword = password;
        let isPasswordChanged = false;
        if (password) {
            if (password.length < 6) {
                setPasswordError('Passwort muss mindestens 6 Zeichen lang sein.');
                return;
            }
            isPasswordChanged = true;
        }

        // Verhindern, dass ein Benutzer seine eigenen Berechtigungen ändert
        if (isEditingSelf) {
            // Stelle sicher, dass Berechtigungen unverändert bleiben
            const originalUser = { ...user };
            currentEditEmployee.can_edit = originalUser.can_edit;
            currentEditEmployee.admin = originalUser.admin;
            currentEditEmployee.super_admin = originalUser.super_admin;
        }

        try {
            const updateData: UpdateEmployeeRequest = {
                currentUser: user!, // Sicherstellen, dass `user` nicht null ist
                mitarbeiter: currentEditEmployee,
                newPassword: isPasswordChanged ? finalPassword : undefined,
                newWebAuthnKey: undefined,
            };
            await axios.put<MessageResponse>(`/api/employees/${currentEditEmployee.id}`, updateData);
            // Nach dem Update die Mitarbeiterliste neu laden
            await fetchEmployees();
            handleEditClose();
            if (isPasswordChanged) {
                alert('Passwort erfolgreich aktualisiert.');
            }
        } catch (error) {
            console.error('Error updating employee:', error);
            alert('Fehler beim Aktualisieren des Mitarbeiters.');
        }
    };

    // Funktion zum Einreichen des Hinzufügen-Dialogs
    const handleSubmit = async () => {
        // Validierungen
        if (!newEmployee.id) {
            setIdError('Mitarbeiter-ID ist erforderlich.');
            return;
        }

        // Überprüfen, ob die ID bereits existiert oder die aktuelle Benutzer-ID ist
        if (employees.some(emp => emp.id === newEmployee.id)) {
            setIdError('Mitarbeiter-ID existiert bereits.');
            return;
        }

        if (newEmployee.id === user?.id) {
            setIdError('Du kannst keinen neuen Mitarbeiter mit deiner eigenen ID erstellen.');
            return;
        }

        if (!newEmployee.vorname || !newEmployee.name) {
            alert('Vorname und Name sind erforderlich.');
            return;
        }
        if (!newEmployee.storeIds || newEmployee.storeIds.length === 0) {
            alert('Mindestens eine Store ID ist erforderlich.');
            return;
        }

        // Hierarchie-Prüfung im Frontend (zusätzliche Sicherheitsmaßnahme)
        if (newEmployee.super_admin && !user?.super_admin) {
            alert('Nur Super-Admins können Super-Admins erstellen.');
            return;
        }
        if (newEmployee.admin && !assignablePermissions.admin) {
            alert('Sie haben keine Berechtigung, Admins zu erstellen.');
            return;
        }
        if (newEmployee.can_edit && !assignablePermissions.can_edit) {
            alert('Sie haben keine Berechtigung, can_edit Benutzer zu erstellen.');
            return;
        }

        // Sicherstellen, dass der Benutzer nicht höhere Berechtigungen zuweist als erlaubt
        const mitarbeiterBerechtigungen = {
            can_edit: newEmployee.can_edit || false,
            admin: newEmployee.admin || false,
            super_admin: newEmployee.super_admin || false,
        };

        if (mitarbeiterBerechtigungen.super_admin && !assignablePermissions.super_admin) {
            alert('Sie haben keine Berechtigung, Super Admins zuzuweisen.');
            return;
        }
        if (mitarbeiterBerechtigungen.admin && !assignablePermissions.admin) {
            alert('Sie haben keine Berechtigung, Admins zuzuweisen.');
            return;
        }
        if (mitarbeiterBerechtigungen.can_edit && !assignablePermissions.can_edit) {
            alert('Sie haben keine Berechtigung, can_edit Benutzer zuzuweisen.');
            return;
        }

        // Automatisches Erstellen eines UUID-Passworts, falls kein Passwort angegeben ist und keine speziellen Rechte gesetzt sind
        let finalPassword = password;
        let isAutoGenerated = false;
        if (!newEmployee.can_edit && !newEmployee.admin && !newEmployee.super_admin && !password) {
            finalPassword = uuidv4();
            isAutoGenerated = true;
        }

        if (finalPassword && finalPassword.length < 6) {
            setPasswordError('Passwort muss mindestens 6 Zeichen lang sein.');
            return;
        }

        // Automatische Zuordnung der aktuellen Filiale sicherstellen
        if (selectedStoreId && !newEmployee.storeIds.includes(selectedStoreId)) {
            newEmployee.storeIds = [selectedStoreId, ...(newEmployee.storeIds || [])];
        }

        const employeeToAdd: CreateEmployeeRequest = {
            mitarbeiter: {
                id: newEmployee.id!,
                vorname: newEmployee.vorname!,
                name: newEmployee.name!,
                email: newEmployee.email,
                storeIds: [selectedStoreId!],
                can_edit: mitarbeiterBerechtigungen.can_edit,
                admin: mitarbeiterBerechtigungen.admin,
                super_admin: mitarbeiterBerechtigungen.super_admin,
            },
            password: finalPassword!,
            webAuthnKey: null,
        };

        try {
            await addEmployee(employeeToAdd);
            handleClose();
            if (isAutoGenerated && finalPassword) {
                alert(`Ein sicheres Passwort wurde automatisch erstellt: ${finalPassword}`);
            }
        } catch (error) {
            console.error('Error adding employee:', error);
            alert('Fehler beim Hinzufügen des Mitarbeiters.');
        }
    };

    // Funktion zum Öffnen des Bearbeiten-Dialogs
    const handleEdit = (employee: Mitarbeiter) => {
        setCurrentEditEmployee({ ...employee });
        setEditOpen(true);
    };

    // Funktion zum Löschen eines Mitarbeiters
    const handleDelete = async (id: string) => {
        if (window.confirm('Möchten Sie diesen Mitarbeiter wirklich löschen?')) {
            try {
                await deleteEmployee(id);
            } catch (error) {
                console.error('Error deleting employee:', error);
                alert('Fehler beim Löschen des Mitarbeiters.');
            }
        }
    };

    // Funktion zum Bedingten Rendern der Edit- und Delete-Buttons basierend auf den Rechten des aktuellen Benutzers
    const canEditEmployee = (employee: Mitarbeiter): boolean => {
        if (employee.super_admin && !user?.super_admin) return false;
        if (employee.admin && !(user?.admin || user?.super_admin)) return false;
        if (employee.can_edit && !(user?.can_edit || user?.admin || user?.super_admin)) return false;
        return true;
    };

    const canCreateEmployee = (): boolean => {
        // Benutzer kann einen neuen Mitarbeiter erstellen, wenn er mindestens can_edit Rechte hat
        return user?.can_edit || user?.admin || user?.super_admin || false;
    };

    // Funktion zum Laden der Mitarbeiter nach einem Update


    // Bestimme, ob der bearbeitete Mitarbeiter der aktuelle Benutzer ist
    const isCurrentUser = currentEditEmployee?.id === user?.id;

    console.log('isCurrentUser:', isCurrentUser);
    console.log('assignablePermissions:', assignablePermissions);

    return (
        <Box p={2}>
            <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleClickOpen}
                disabled={!canCreateEmployee()}
            >
                Mitarbeiter hinzufügen
            </Button>
            <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Vorname</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Store IDs</TableCell>
                            <TableCell>Berechtigungen</TableCell>
                            <TableCell>Aktionen</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {employees.map((employee: Mitarbeiter) => (
                            <TableRow key={employee.id}>
                                <TableCell>{employee.id}</TableCell>
                                <TableCell>{employee.vorname}</TableCell>
                                <TableCell>{employee.name}</TableCell>
                                <TableCell>{employee.email || '-'}</TableCell>
                                <TableCell>
                                    {employee.storeIds.map((id: string) => (
                                        <Chip
                                            key={id}
                                            label={id}
                                            color="primary"
                                            variant="outlined"
                                            sx={{ mr: 0.5, mb: 0.5 }}
                                        />
                                    ))}
                                </TableCell>
                                <TableCell>
                                    {employee.can_edit && <Chip label="WE-Manager" color="secondary" size="small" sx={{ mr: 0.5 }} />}
                                    {employee.admin && <Chip label="Filialleiter" color="warning" size="small" sx={{ mr: 0.5 }} />}
                                    {employee.super_admin && <Chip label="Admin" color="error" size="small" />}
                                </TableCell>
                                <TableCell>
                                    <Tooltip title="Mitarbeiter bearbeiten">
                                        <span>
                                            <IconButton
                                                color="primary"
                                                onClick={() => handleEdit(employee)}
                                                disabled={!canEditEmployee(employee)}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                    <Tooltip title="Mitarbeiter löschen">
                                        <span>
                                            <IconButton
                                                color="secondary"
                                                onClick={() => handleDelete(employee.id)}
                                                disabled={!canEditEmployee(employee) || (user?.id === employee.id)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                        {employees.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={7} align="center">
                                    Keine Mitarbeiter gefunden.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Dialog zum Hinzufügen eines neuen Mitarbeiters */}
            <Dialog
                open={open}
                onClose={handleClose}
                fullScreen={fullScreen}
                aria-labelledby="add-employee-dialog-title"
            >
                <DialogTitle id="add-employee-dialog-title">Neuen Mitarbeiter hinzufügen</DialogTitle>
                <DialogContent>
                    <Box component="form" sx={{ mt: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    label="Mitarbeiter Nummer"
                                    name="id"
                                    fullWidth
                                    value={newEmployee.id}
                                    onChange={handleInputChange}
                                    error={!!idError}
                                    helperText={idError}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    label="Vorname"
                                    name="vorname"
                                    fullWidth
                                    value={newEmployee.vorname}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    label="Name"
                                    name="name"
                                    fullWidth
                                    value={newEmployee.name}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Email"
                                    name="email"
                                    type="email"
                                    fullWidth
                                    value={newEmployee.email}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">Store IDs</Typography>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs={8} sm={10}>
                                        <TextField
                                            label="Neue Store ID"
                                            value={storeIdInput}
                                            onChange={(e) => setStoreIdInput(e.target.value.toUpperCase())}
                                            error={!!storeIdError}
                                            helperText={storeIdError}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={2}>
                                        <Button variant="contained" color="primary" onClick={handleAddStoreId} fullWidth>
                                            Hinzufügen
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Box mt={1}>
                                    {newEmployee.storeIds && newEmployee.storeIds.length > 0 ? (
                                        newEmployee.storeIds.map((id) => (
                                            <Chip
                                                key={id}
                                                label={id + (id === selectedStoreId ? ' (Aktuell)' : '')}
                                                onDelete={() => handleRemoveStoreId(id)}
                                                color={id === selectedStoreId ? 'primary' : 'default'}
                                                variant="outlined"
                                                sx={{ mr: 0.5, mb: 0.5 }}
                                            />
                                        ))
                                    ) : (
                                        <Typography variant="body2">Keine Store IDs hinzugefügt.</Typography>
                                    )}
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Passwort (optional)"
                                    name="password"
                                    type="password"
                                    fullWidth
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    error={!!passwordError}
                                    helperText={passwordError || 'Falls kein Passwort angegeben wird, wird automatisch ein sicheres Passwort erstellt.'}
                                />
                            </Grid>
                            {/* Berechtigungen nur anzeigen, wenn der Benutzer Berechtigungen vergeben darf */}
                            {(assignablePermissions.can_edit ||
                                assignablePermissions.admin ||
                                assignablePermissions.super_admin) && (
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1">Berechtigungen</Typography>
                                    <Grid container spacing={1}>
                                        {assignablePermissions.can_edit && (
                                            <Grid item xs={12} sm={4}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={newEmployee.can_edit || false}
                                                            onChange={handleInputChange}
                                                            name="can_edit"
                                                            color="primary"
                                                        />
                                                    }
                                                    label="WE-Manager"
                                                />
                                            </Grid>
                                        )}
                                        {assignablePermissions.admin && (
                                            <Grid item xs={12} sm={4}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={newEmployee.admin || false}
                                                            onChange={handleInputChange}
                                                            name="admin"
                                                            color="secondary"
                                                        />
                                                    }
                                                    label="Filialleiter"
                                                />
                                            </Grid>
                                        )}
                                        {assignablePermissions.super_admin && (
                                            <Grid item xs={12} sm={4}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={newEmployee.super_admin || false}
                                                            onChange={handleInputChange}
                                                            name="super_admin"
                                                            color="error"
                                                        />
                                                    }
                                                    label="Admin"
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Abbrechen
                    </Button>
                    <Button onClick={handleSubmit} color="primary" variant="contained" disabled={!canCreateEmployee()}>
                        Hinzufügen
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Dialog zum Bearbeiten eines Mitarbeiters */}
            <Dialog
                open={editOpen}
                onClose={handleEditClose}
                fullScreen={fullScreen}
                aria-labelledby="edit-employee-dialog-title"
            >
                <DialogTitle id="edit-employee-dialog-title">Mitarbeiter bearbeiten</DialogTitle>
                <DialogContent>
                    <Box component="form" sx={{ mt: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    label="Mitarbeiter-ID"
                                    name="id"
                                    fullWidth
                                    value={currentEditEmployee?.id || ''}
                                    onChange={handleEditChange}
                                    disabled // ID sollte nicht bearbeitbar sein
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    label="Vorname"
                                    name="vorname"
                                    fullWidth
                                    value={currentEditEmployee?.vorname || ''}
                                    onChange={handleEditChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    label="Name"
                                    name="name"
                                    fullWidth
                                    value={currentEditEmployee?.name || ''}
                                    onChange={handleEditChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Email"
                                    name="email"
                                    type="email"
                                    fullWidth
                                    value={currentEditEmployee?.email || ''}
                                    onChange={handleEditChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">Store IDs</Typography>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs={8} sm={10}>
                                        <TextField
                                            label="Neue Store ID"
                                            value={storeIdInput}
                                            onChange={(e) => setStoreIdInput(e.target.value.toUpperCase())}
                                            error={!!storeIdError}
                                            helperText={storeIdError}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={2}>
                                        <Button variant="contained" color="primary" onClick={handleEditAddStoreId} fullWidth>
                                            Hinzufügen
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Box mt={1}>
                                    {currentEditEmployee?.storeIds && currentEditEmployee.storeIds.length > 0 ? (
                                        currentEditEmployee.storeIds.map((id) => (
                                            <Chip
                                                key={id}
                                                label={id + (id === selectedStoreId ? ' (Aktuell)' : '')}
                                                onDelete={() => handleEditRemoveStoreId(id)}
                                                color={id === selectedStoreId ? 'primary' : 'default'}
                                                variant="outlined"
                                                sx={{ mr: 0.5, mb: 0.5 }}
                                            />
                                        ))
                                    ) : (
                                        <Typography variant="body2">Keine Store IDs hinzugefügt.</Typography>
                                    )}
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Neues Passwort (optional)"
                                    name="newPassword"
                                    type="password"
                                    fullWidth
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    error={!!passwordError}
                                    helperText={passwordError || 'Optional: Passwort ändern'}
                                />
                            </Grid>
                            {/* Berechtigungen nur anzeigen, wenn der Benutzer Berechtigungen vergeben darf und nicht der aktuelle Benutzer bearbeitet wird */}
                            {(!isCurrentUser) && (assignablePermissions.can_edit ||
                                assignablePermissions.admin ||
                                assignablePermissions.super_admin) && (
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1">Berechtigungen</Typography>
                                    <Grid container spacing={1}>
                                        {assignablePermissions.can_edit && (
                                            <Grid item xs={12} sm={4}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={currentEditEmployee?.can_edit || false}
                                                            onChange={handleEditChange}
                                                            name="can_edit"
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Bearbeiten"
                                                />
                                            </Grid>
                                        )}
                                        {assignablePermissions.admin && (
                                            <Grid item xs={12} sm={4}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={currentEditEmployee?.admin || false}
                                                            onChange={handleEditChange}
                                                            name="admin"
                                                            color="secondary"
                                                        />
                                                    }
                                                    label="Admin"
                                                />
                                            </Grid>
                                        )}
                                        {assignablePermissions.super_admin && (
                                            <Grid item xs={12} sm={4}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={currentEditEmployee?.super_admin || false}
                                                            onChange={handleEditChange}
                                                            name="super_admin"
                                                            color="error"
                                                        />
                                                    }
                                                    label="Super Admin"
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                            {/* Optional: Hinweis, dass eigene Berechtigungen nicht bearbeitet werden können */}
                            {isCurrentUser && (
                                <Grid item xs={12}>
                                    <Typography variant="body2" color="textSecondary">
                                        Du kannst deine eigenen Berechtigungen nicht bearbeiten.
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditClose} color="secondary">
                        Abbrechen
                    </Button>
                    <Button onClick={handleUpdateEmployee} color="primary" variant="contained" disabled={!currentEditEmployee}>
                        Speichern
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );

}

export default MitarbeiterComponent;
