// frontend/src/components/Main.tsx

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { CssBaseline, useMediaQuery, ThemeProvider, createTheme } from '@mui/material';
import Home from './Home';
import Navigation from './Navigation';
import Mitarbeiter from './Mitarbeiter';
import RequireAuth from './RequireAuth';
import Paletten from './Paletten';
import PorzessErstellung from './ProcessCreation';
import ProcessManagement from './ProcessManagement';
import { useAuthContext } from '../context/AuthContext';
import Import from './import';

const Main: React.FC = () => {
  const { user } = useAuthContext();
  // System-Themenpräferenz verwenden
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  // Erstelle ein Theme-Objekt
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
          primary: {
            main: '#1976d2',
          },
        },
      }),
    [prefersDarkMode],
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div style={{marginBottom : "10px"} }>
                      <Navigation />

      </div>

      <RequireAuth>
        <Routes>
          <Route path="/" element={<Home />} />
         
          { user?.can_edit && (
             <Route path="/employees" element={<Mitarbeiter />} />
          )}
          <Route path="/import" element={<Import />} />

          <Route path="/palettes" element={<Paletten />} />
          <Route path="/processes" element={<ProcessManagement />} />
          {/* Weitere Routen nach Bedarf */}
        </Routes>
      </RequireAuth>
    </ThemeProvider>
  );
};

export default Main;
