// frontend/src/components/ProcessManagement.tsx

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Typography,
  CircularProgress,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
  useTheme,
  Alert,
  Checkbox,
  ListItemText,
  OutlinedInput,
} from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  Prozess,
  Artikel,
  Mitarbeiter,
  Artikel_Prozess,
  Geatmet,
  Zeitbedarf_Prozess,
} from '../types/api';
import { useAuthContext } from '../context/AuthContext';
import { useEmployeeContext } from '../context/Mitarbeiter';
import api from '../services/api';
import CloseIcon from '@mui/icons-material/Close';
import BarcodeScanner from './Sub/Scanner';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import deLocale from 'date-fns/locale/de';
import { SelectChangeEvent } from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { format } from 'date-fns';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ProcessManagement: React.FC = () => {
  const { user, selectedStoreId } = useAuthContext();
  const { employees } = useEmployeeContext();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [processList, setProcessList] = useState<Prozess[]>([]);
  const [fetchingProcesses, setFetchingProcesses] = useState<boolean>(true);

  const [filterVon, setFilterVon] = useState<Date | null>(null);
  const [filterBis, setFilterBis] = useState<Date | null>(null);
  const [filterStatus, setFilterStatus] = useState<'open' | 'closed' | ''>('open');
  const [filterEmployees, setFilterEmployees] = useState<string[]>([]); // Changed to array
  const [filterErrorType, setFilterErrorType] = useState<'all' | 'employee_error' | 'non_employee_error'>('all');

  const [selectedProcess, setSelectedProcess] = useState<Prozess | null>(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isCreatingProcess, setIsCreatingProcess] = useState<boolean>(false);
  const [isEditingProcess, setIsEditingProcess] = useState<boolean>(false);

  const [isScanning, setIsScanning] = useState(false);
  const [scannedGTIN, setScannedGTIN] = useState<string>('');
  const [article, setArticle] = useState<Artikel | null>(null);
  const [loadingArticle, setLoadingArticle] = useState(false);

  const [selectedIssue, setSelectedIssue] = useState<Artikel_Prozess | null>(null);
  const [geatmetOption, setGeatmetOption] = useState<Geatmet | ''>('');
  const [zeitbedarfOption, setZeitbedarfOption] = useState<Zeitbedarf_Prozess | ''>('');
  const [additionalInfo, setAdditionalInfo] = useState<string>('');

  const [selectedFoto, setSelectedFoto] = useState<File | null>(null);

  // State für Export Feedback
  const [exporting, setExporting] = useState<boolean>(false);
  const [exportSuccess, setExportSuccess] = useState<string | null>(null);
  const [exportError, setExportError] = useState<string | null>(null);

  const errorTypes = [
    Artikel_Prozess.Fehlerhaft_verräumt,
    Artikel_Prozess.Nicht_gefunden,
    Artikel_Prozess.Unzureichend_Geatmet,
  ];

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    resetProcessForm();
  };

  const resetProcessForm = () => {
    setIsCreatingProcess(false);
    setIsEditingProcess(false);
    setSelectedProcess(null);
    setArticle(null);
    setScannedGTIN('');
    setSelectedIssue(null);
    setGeatmetOption('');
    setZeitbedarfOption('');
    setAdditionalInfo('');
    setSelectedFoto(null); // Reset des ausgewählten Fotos
  };

  const fetchProcesses = async () => {
    setFetchingProcesses(true);
    try {
      const params = new URLSearchParams();
      if (filterVon) {
        params.append('von', filterVon.toISOString().split('T')[0]);
      }
      if (filterBis) {
        params.append('bis', filterBis.toISOString().split('T')[0]);
      }
      if (filterStatus) {
        params.append('status', filterStatus);
      }
      if (filterEmployees && filterEmployees.length > 0) {
        filterEmployees.forEach(id => params.append('mitarbeiterIds', id));
      }
      if (filterErrorType && filterErrorType !== 'all') {
        if (filterErrorType === 'employee_error') {
          params.append('has_error', 'true');
          params.append('verraeumende_mitarbeiter_nummer_neu', 'true');
        } else if (filterErrorType === 'non_employee_error') {
          params.append('has_error', 'true');
          params.append('verraeumende_mitarbeiter_nummer_neu', 'false');
        }
      }
      if (selectedStoreId) {
        params.append('storeId', selectedStoreId);
      }

      const response = await api.get(`/api/processes?${params.toString()}`);
      setProcessList(response.data);
    } catch (err) {
      console.error('Fehler beim Abrufen der Prozesse:', err);
    } finally {
      setFetchingProcesses(false);
    }
  };

  useEffect(() => {
    if (!isMobile) {
      fetchProcesses();
    }
  }, [filterVon, filterBis, filterStatus, filterEmployees, filterErrorType, selectedStoreId, isMobile]);


  const handleProcessSelection = (process: Prozess) => {
    setSelectedProcess(process);
    setIsEditingProcess(true);
    handleDialogOpen();
    setSelectedIssue(process.artikel_prozess || null);
    setGeatmetOption(process.geatmet || '');
    setZeitbedarfOption(process.zeitbedarf || '');
    setAdditionalInfo(process.zusatzliche_informationen || '');
    fetchArticle(process.gtin || '');
  };

  const fetchArticle = async (gtin: string) => {
    if (!selectedStoreId) return;
    setLoadingArticle(true);
    try {
      const response = await api.get(`/api/articles/${selectedStoreId}/${gtin}`);
      setArticle(response.data);
    } catch (err) {
      console.error('Fehler beim Abrufen des Artikels:', err);
      setArticle(null);
    } finally {
      setLoadingArticle(false);
    }
  };

  const handleScan = useCallback(
    async (Ergebnis: any) => {
      let data = Ergebnis;
      console.log('Gescanntes Ergebnis:', data);
      setScannedGTIN(data);
      setIsScanning(false);

      try {
        setLoadingArticle(true);
        const response = await api.get(`/api/articles/${selectedStoreId}/${data}`);
        setArticle(response.data);
      } catch (error) {
        console.error('Fehler beim Abrufen des Artikels:', error);
        setArticle(null);
      } finally {
        setLoadingArticle(false);
      }
    },
    [selectedStoreId]
  );

  const toggleScanner = () => {
    setIsScanning((prev) => !prev);

    if (!isScanning) {
      setArticle(null);
      setScannedGTIN('');
      setSelectedIssue(null);
      setGeatmetOption('');
      setZeitbedarfOption('');
      setAdditionalInfo('');
    }
  };

  const handleSubmitProcess = async () => {
    if (!article || !selectedStoreId) return;
    const processData: Partial<Prozess> = {
      gtin: article.gtin,
      artikel_prozess: selectedIssue,
      geatmet: geatmetOption || null,
      zeitbedarf: zeitbedarfOption || null,
      datum: new Date().toISOString().split('T')[0],
      store_id: selectedStoreId,
      zusatzliche_informationen: additionalInfo,
    };

    // Setze has_error basierend auf ausgewähltem artikel_prozess
    if (
      selectedIssue === Artikel_Prozess.Fehlerhaft_verräumt ||
      selectedIssue === Artikel_Prozess.Nicht_gefunden ||
      selectedIssue === Artikel_Prozess.Unzureichend_Geatmet
    ) {
      processData.has_error = true;
    } else {
      processData.has_error = false;
    }

    if (selectedIssue === Artikel_Prozess.Falscher_Bestand) {
      processData.is_closed = true;
      processData.has_error = false;
    }

    if (isEditingProcess && selectedProcess) {
      processData.verraeumende_mitarbeiter_nummer = selectedProcess.verraeumende_mitarbeiter_nummer;
    }

    await createProcess(processData);
  };

  const createProcess = async (processData: Partial<Prozess>) => {
    try {
      if (isEditingProcess && selectedProcess) {
        // Erstellen Sie ein FormData-Objekt für die Aktualisierung
        const formData = new FormData();
        formData.append('prozessData', JSON.stringify(processData));
        if (selectedFoto) {
          formData.append('foto', selectedFoto);
        }

        await api.put(`/api/processes/${selectedProcess.uuid}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        await fetchProcesses();
      } else {
        // Erstellen Sie ein FormData-Objekt für die Erstellung
        const formData = new FormData();
        formData.append('prozessData', JSON.stringify(processData));
        if (selectedFoto) {
          formData.append('foto', selectedFoto);
        }

        const response = await api.post('/api/processes', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        if (!isMobile) {
          await fetchProcesses();
        }
      }
      handleDialogClose();
    } catch (error) {
      console.error('Fehler beim Speichern des Prozesses:', error);
    }
  };

  const handleCloseProcess = async () => {
    if (!selectedProcess) return;
    try {
      const updates: Partial<Prozess> = {
        is_closed: true,
        has_error: errorTypes.includes(selectedProcess.artikel_prozess as Artikel_Prozess),
      };

      const formData = new FormData();
      formData.append('prozessData', JSON.stringify(updates));

      await api.put(`/api/processes/${selectedProcess.uuid}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      await fetchProcesses();
      handleDialogClose();
    } catch (error) {
      console.error('Fehler beim Abschließen des Prozesses:', error);
    }
  };

  const handleCloseProcessFromTable = async (process: Prozess) => {
    try {
      const updates: Partial<Prozess> = {
        is_closed: true,
        has_error: errorTypes.includes(process.artikel_prozess as Artikel_Prozess),
      };

      const formData = new FormData();
      formData.append('prozessData', JSON.stringify(updates));

      await api.put(`/api/processes/${process.uuid}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      await fetchProcesses();
    } catch (error) {
      console.error('Fehler beim Abschließen des Prozesses:', error);
    }
  };

  const handleFotoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFoto(e.target.files[0]);
    }
  };

  const CustomNoRowsOverlay = React.memo(() => (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <Typography>Keine Prozesse gefunden.</Typography>
    </Box>
  ));

  const handleExport = async () => {
    setExporting(true);
    setExportSuccess(null);
    setExportError(null);
    try {
      const exportData = {
        von: filterVon ? filterVon.toISOString().split('T')[0] : undefined,
        bis: filterBis ? filterBis.toISOString().split('T')[0] : undefined,
        status: filterStatus || undefined,
        mitarbeiterIds: filterEmployees.length > 0 ? filterEmployees : undefined,
        errorType: filterErrorType !== 'all' ? filterErrorType : undefined,
        storeId: selectedStoreId || undefined,
      };
      const Dokument = await api.post('/api/processes/export', exportData, {
        responseType: 'blob', // Wichtiger Schritt für den Blob
      });
      // Empfängt einen PDF-Bericht als Antwort
      const blob = new Blob([Dokument.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'bericht.pdf';
      a.click();
      window.URL.revokeObjectURL(url);

      setExportSuccess('Export erfolgreich gestartet.');
    } catch (error) {
      console.error('Fehler beim Exportieren der Daten:', error);
      setExportError('Fehler beim Exportieren der Daten.');
    } finally {
      setExporting(false);
    }
  };

  const columns: GridColDef[] = [
    { field: 'gtin', headerName: 'GTIN', width: 150 },
    {
      field: 'datum',
      headerName: 'Datum',
      width: 120,
      renderCell: (params) => format(new Date(params.row.datum), 'dd.MM.yyyy'),
    },
    { field: 'artikel_prozess', headerName: 'Problem', width: 200 },
    {
      field: 'is_closed',
      headerName: 'Status',
      width: 120,
      renderCell: (params: GridRenderCellParams) => (
        <Typography>{params.row.is_closed ? 'Geschlossen' : 'Offen'}</Typography>
      ),
    },
    {
      field: 'creator',
      headerName: 'Ersteller',
      width: 200,
      renderCell: (params) => {
        const mitarbeiterNummer = params.row.mitarbeiter_nummer;
        const employee = employees.find((emp) => emp.id === mitarbeiterNummer);
        return employee ? `${employee.vorname} ${employee.name}` : 'Unbekannt';
      },
    },
    {
      field: 'verraeumende_mitarbeiter',
      headerName: 'Verräumender Mitarbeiter',
      width: 220, // Erhöht die Breite für bessere Darstellung der langen Texte
      renderCell: (params) => {
        if (params.row.verraeumende_mitarbeiter_nummer) {
          const employee = employees.find(emp => emp.id === params.row.verraeumende_mitarbeiter_nummer);
          return employee ? `${employee.vorname} ${employee.name}` : 'Unbekannt';
        }
        return '-';
      },
    },
    {
      field: 'actions',
      headerName: 'Aktionen',
      width: 300,
      renderCell: (params: GridRenderCellParams<Prozess>) => {
        const isErrorType = errorTypes.includes(params.row.artikel_prozess as Artikel_Prozess);
        return (
          <Box display="flex" gap={1} flexWrap="nowrap">
            <Button
              variant="contained"
              size="small"
              onClick={() => handleProcessSelection(params.row)}
            >
              Bearbeiten
            </Button>
            {!params.row.is_closed && (
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={() => handleCloseProcessFromTable(params.row)}
              >
                {isErrorType ? 'Mit Mitarbeiter gesprochen' : 'Prozess beendet'}
              </Button>
            )}
          </Box>
        );
      },
    },
  ];

  return (
    <Box p={3}>
      {isMobile ? (
        <Box display="flex" justifyContent="center" mb={2}>
          <Button
            variant="contained"
            onClick={() => {
              setIsCreatingProcess(true);
              handleDialogOpen();
            }}
            size="large"
            fullWidth
            sx={{ maxWidth: 300 }}
          >
            Prozess erstellen
          </Button>
        </Box>
      ) : (
        <>
          <Box display="flex" alignItems="center" mb={2} flexWrap="wrap" gap={2}>
            <Button
              variant="contained"
              onClick={() => {
                setIsCreatingProcess(true);
                handleDialogOpen();
              }}
            >
              Neuer Prozess
            </Button>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
                <DatePicker
                  label="Von"
                  value={filterVon}
                  onChange={(date: Date | null) => setFilterVon(date)}
                  renderInput={(params: any) => <TextField {...params} size="small" />}
                />
              </LocalizationProvider>
            </Box>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
                <DatePicker
                  label="Bis"
                  value={filterBis}
                  onChange={(date: Date | null) => setFilterBis(date)}
                  renderInput={(params: any) => <TextField {...params} size="small" />}
                />
              </LocalizationProvider>
            </Box>
            <Box>
              <FormControl size="small">
                <InputLabel>Status</InputLabel>
                <Select<'open' | 'closed' | ''>
                  value={filterStatus}
                  onChange={(e: SelectChangeEvent<'open' | 'closed' | ''>) =>
                    setFilterStatus(e.target.value as 'open' | 'closed' | '')
                  }
                  label="Status"
                  style={{ minWidth: 120 }}
                >
                  <MenuItem value="">Alle</MenuItem>
                  <MenuItem value="open">Offen</MenuItem>
                  <MenuItem value="closed">Geschlossen</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box>
              <FormControl size="small" sx={{ minWidth: 200 }}>
                <InputLabel>Mitarbeiter</InputLabel>
                <Select<string[]>
                  multiple
                  value={filterEmployees}
                  onChange={(e: SelectChangeEvent<string[]>) => setFilterEmployees(e.target.value as string[])}
                  label="Mitarbeiter"
                  input={<OutlinedInput label="Mitarbeiter" />}
                  renderValue={(selected) => selected.map(id => {
                    const emp = employees.find(emp => emp.id === id);
                    return emp ? `${emp.vorname} ${emp.name}` : id;
                  }).join(', ')}
                  MenuProps={MenuProps}
                >
                  {employees.map((emp) => (
                    <MenuItem key={emp.id} value={emp.id}>
                      <Checkbox checked={filterEmployees.indexOf(emp.id) > -1} />
                      <ListItemText primary={`${emp.vorname} ${emp.name}`} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box>
              <FormControl size="small">
                <InputLabel>Fehlertyp</InputLabel>
                <Select<'all' | 'employee_error' | 'non_employee_error'>
                  value={filterErrorType}
                  onChange={(e: SelectChangeEvent<'all' | 'employee_error' | 'non_employee_error'>) =>
                    setFilterErrorType(e.target.value as 'all' | 'employee_error' | 'non_employee_error')
                  }
                  label="Fehlertyp"
                  style={{ minWidth: 180 }}
                >
                  <MenuItem value="all">Alle</MenuItem>
                  <MenuItem value="employee_error">Mitarbeiterfehler</MenuItem>
                  <MenuItem value="non_employee_error">Andere Fehler</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box>
              <Button
                variant="outlined"
                startIcon={<SaveAltIcon />}
                onClick={handleExport}
                disabled={exporting}
              >
                Export
              </Button>
            </Box>
          </Box>
          {exportSuccess && (
            <Alert severity="success" onClose={() => setExportSuccess(null)} sx={{ mb: 2 }}>
              {exportSuccess}
            </Alert>
          )}
          {exportError && (
            <Alert severity="error" onClose={() => setExportError(null)} sx={{ mb: 2 }}>
              {exportError}
            </Alert>
          )}
          <Box mt={3}>
            {fetchingProcesses ? (
              <CircularProgress />
            ) : (
              <DataGrid
                rows={processList}
                columns={columns}
                getRowId={(row) => row.uuid}
                autoHeight
                disableColumnMenu

              />
            )}
          </Box>
        </>
      )}
      <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth maxWidth="sm">
        <DialogTitle>
          {isEditingProcess ? 'Prozess bearbeiten' : 'Neuen Prozess erstellen'}
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {!isEditingProcess && (
            <Box marginBottom={2}>
              {!article && (
                <Button variant="contained" onClick={toggleScanner}>
                  {isScanning ? 'Scanner stoppen' : 'Artikel scannen'}
                </Button>
              )}
              {isScanning && (
                <Box marginBottom={2}>
                  <BarcodeScanner
                    onDetected={handleScan}
                  />
                </Box>
              )}
            </Box>
          )}
          {loadingArticle && (
            <Box display="flex" justifyContent="center" alignItems="center" marginTop={2}>
              <CircularProgress />
            </Box>
          )}
          {article && (
            <Box>
              <Typography variant="h6">Artikelinformationen:</Typography>
              <Typography>Name: {article.name}</Typography>
              <Typography>GTIN: {article.gtin}</Typography>
              <Typography>DAN: {article.dan}</Typography>
              <Typography>Bestand: {article.Bestand !== undefined ? article.Bestand : 'Nicht verfügbar'}</Typography>
            </Box>
          )}
          {isEditingProcess && !article && (
            <Box>
              <Typography variant="h6">Artikelinformationen:</Typography>
              <Typography>GTIN: {selectedProcess?.gtin}</Typography>
            </Box>
          )}
          {(article || isEditingProcess) && (
            <Box marginTop={4}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="legend">Problem mit dem Artikel</FormLabel>
                <RadioGroup
                  value={selectedIssue}
                  onChange={(e) => setSelectedIssue(e.target.value as Artikel_Prozess)}
                >
                  {Object.values(Artikel_Prozess).map((issue) => (
                    <FormControlLabel
                      key={issue}
                      value={issue}
                      control={<Radio />}
                      label={issue}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              {selectedIssue === Artikel_Prozess.Unzureichend_Geatmet && (
                <>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <FormLabel component="legend">Wie wurde geatmet?</FormLabel>
                    <RadioGroup
                      value={geatmetOption}
                      onChange={(e) => setGeatmetOption(e.target.value as Geatmet)}
                    >
                      {Object.values(Geatmet).map((option) => (
                        <FormControlLabel
                          key={option}
                          value={option}
                          control={<Radio />}
                          label={option}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <FormLabel component="legend">Geschätzter Zeitbedarf</FormLabel>
                    <RadioGroup
                      value={zeitbedarfOption}
                      onChange={(e) => setZeitbedarfOption(e.target.value as Zeitbedarf_Prozess)}
                    >
                      {Object.values(Zeitbedarf_Prozess).map((option) => (
                        <FormControlLabel
                          key={option}
                          value={option}
                          control={<Radio />}
                          label={option}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </>
              )}
              {selectedIssue === Artikel_Prozess.Falscher_Bestand && (
                <Typography color="error" variant="body2">
                  Hinweis: Der Bestand muss unbedingt im dmSAP korrigiert werden!
                </Typography>
              )}
              {isEditingProcess && (
                <TextField
                  label="Zusätzliche Informationen"
                  multiline
                  rows={4}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={additionalInfo}
                  onChange={(e) => setAdditionalInfo(e.target.value)}
                />
              )}
              {isEditingProcess && selectedIssue && errorTypes.includes(selectedIssue) && (
                <FormControl fullWidth margin="normal">
                  <InputLabel>Verräumender Mitarbeiter</InputLabel>
                  <Select<string>
                    value={selectedProcess?.verraeumende_mitarbeiter_nummer || ''}
                    disabled={true}
                    label="Verräumender Mitarbeiter"
                  >
                    <MenuItem value="">Keine</MenuItem>
                    {employees.map((emp) => (
                      <MenuItem key={emp.id} value={emp.id}>
                        {emp.vorname} {emp.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {/* Anzeige des Fotos, wenn vorhanden */}
              {isEditingProcess && selectedProcess && selectedProcess.has_photo ? (
                <Box mt={2}>
                  <Typography variant="h6">Foto zum Prozess:</Typography>
                  <img
                    src={`/images/${selectedStoreId}/${selectedProcess.uuid}.png`}
                    alt="Prozess Foto"
                    style={{ maxWidth: '100%', maxHeight: 300 }}
                  />
                </Box>
              ) : (
                <Box marginTop={2}>
                  <InputLabel htmlFor="foto-upload">Foto hinzufügen (optional)</InputLabel>
                  <input
                    id="foto-upload"
                    type="file"
                    accept="image/*"
                    onChange={handleFotoChange}
                    style={{ marginTop: '8px' }}
                  />
                  {selectedFoto && (
                    <Typography variant="body2" mt={1}>
                      Ausgewähltes Foto: {selectedFoto.name}
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Abbrechen</Button>
          {isEditingProcess && (
            <Button
              onClick={handleCloseProcess}
              color="secondary"
              variant="contained"
            >
              Prozess abschließen
            </Button>
          )}
          <Button
            onClick={handleSubmitProcess}
            color="primary"
            variant="contained"
            disabled={!selectedIssue}
          >
            {isEditingProcess ? 'Änderungen speichern' : 'Prozess erstellen'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProcessManagement;
