import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// Benutzerinterface definieren (inklusive aller Daten aus der API)
interface User {
  id: string;
  vorname: string;
  name: string;
  email?: string;
  storeIds: string[];
  can_edit: boolean;
  admin: boolean;
  super_admin: boolean;
  // Weitere Benutzerfelder nach Bedarf
}

interface AuthContextType {
  user: User | null;
  isAuthenticated: boolean;
  login: (credentials: { id: string; password: string }) => Promise<void>;
  logout: () => Promise<void>;
  loading: boolean;
  selectedStoreId: string | null;
  setSelectedStoreId: (storeId: string) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext muss innerhalb eines AuthProviders verwendet werden');
  }
  return context;
};

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [selectedStoreId, setSelectedStoreId] = useState<string | null>(
    () => localStorage.getItem('selectedStoreId')
  );
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const fetchUser = async () => {
    setLoading(true);
    try {
      const response = await axios.get<User>('/api/auth/profile');
      setUser(response.data);
      // Setze die Standard-StoreId, falls noch keine ausgewählt wurde
      if (response.data.storeIds.length > 0 && !selectedStoreId) {
        const defaultStoreId = response.data.storeIds[0];
        setSelectedStoreId(defaultStoreId);
        localStorage.setItem('selectedStoreId', defaultStoreId);
      }
    } catch (error) {
      setUser(null);
      setSelectedStoreId(null);
      localStorage.removeItem('selectedStoreId');
    } finally {
      setLoading(false);
    }
  };

  const login = async (credentials: { id: string; password: string }) => {
    try {
      await axios.post('/api/auth/login', credentials);
      await fetchUser();
    } catch (error) {
      throw error;
    }
  };

  const logout = async () => {
    try {
      await axios.post('/api/auth/logout');
      setUser(null);
      setSelectedStoreId(null);
      localStorage.removeItem('selectedStoreId');
      navigate('/login', { replace: true });
    } catch (error) {
      console.error('Fehler beim Abmelden:', error);
    }
  };

  useEffect(() => {
    // Axios-Interceptor einrichten
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          setUser(null);
          setSelectedStoreId(null);
          localStorage.removeItem('selectedStoreId');
          navigate('/login', { replace: true });
        }
        return Promise.reject(error);
      }
    );

    fetchUser();

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [navigate]);

  useEffect(() => {
    if (selectedStoreId) {
      localStorage.setItem('selectedStoreId', selectedStoreId);
    } else {
      localStorage.removeItem('selectedStoreId');
    }
  }, [selectedStoreId]);

  const value = {
    user,
    isAuthenticated: !!user,
    login,
    logout,
    loading,
    selectedStoreId,
    setSelectedStoreId,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
