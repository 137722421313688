// frontend/src/context/Mitarbeiter.tsx

import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import axios from 'axios';
import { Mitarbeiter, CreateEmployeeRequest, GetEmployeesResponse, CreateEmployeeResponse, DeleteEmployeeResponse } from '../types/api';
import { useAuthContext } from './AuthContext';


interface EmployeeContextType {
  employees: Mitarbeiter[];
  fetchEmployees: () => Promise<void>;
  addEmployee: (employee: CreateEmployeeRequest) => Promise<void>;
  deleteEmployee: (id: string) => Promise<void>;
}

const EmployeeContext = createContext<EmployeeContextType | undefined>(undefined);

export const useEmployeeContext = () => {
  const context = useContext(EmployeeContext);
  if (!context) {
    throw new Error('useEmployeeContext muss innerhalb eines EmployeeProviders verwendet werden');
  }
  return context;
};

interface EmployeeProviderProps {
  children: ReactNode;
}

export const EmployeeProvider: React.FC<EmployeeProviderProps> = ({ children }) => {
  const [employees, setEmployees] = useState<Mitarbeiter[]>([]);
  const { selectedStoreId } = useAuthContext();

  const fetchEmployees = async () => {
    if (!selectedStoreId) return;
    try {
      const response = await axios.get<GetEmployeesResponse>(`/api/employees?storeId=${selectedStoreId}`);
      setEmployees(response.data);
    } catch (error) {
      console.error('Fehler beim Abrufen der Mitarbeiter:', error);
    }
  };

  const addEmployee = async (payload: CreateEmployeeRequest) => {
    try {
      await axios.post<CreateEmployeeResponse>('/api/employees', payload);
      await fetchEmployees(); // Aktualisiere die Liste nach dem Hinzufügen
    } catch (error) {
      console.error('Fehler beim Hinzufügen des Mitarbeiters:', error);
      throw error;
    }
  };

  const deleteEmployee = async (id: string) => {
    try {
      await axios.delete<DeleteEmployeeResponse>(`/api/employees/${id}`);
      await fetchEmployees(); // Aktualisiere die Liste nach dem Löschen
    } catch (error) {
      console.error('Fehler beim Löschen des Mitarbeiters:', error);
      throw error;
    }
  };

  // Lade die Mitarbeiter beim Mounten und wenn sich selectedStoreId ändert
  useEffect(() => {
    fetchEmployees();
  }, [selectedStoreId]);

  return (
    <EmployeeContext.Provider value={{ employees, fetchEmployees, addEmployee, deleteEmployee }}>
      {children}
    </EmployeeContext.Provider>
  );
};
