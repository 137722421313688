// frontend/src/services/api.ts
import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

// Interceptor hinzufügen
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Weiterleitung zur Anmeldeseite
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

// Beispiel einer API-Methode zum Abrufen des Benutzerprofils
export const getProfile = async () => {
  const response = await api.get('/api/auth/profile');
  return response.data;
};

export default api;
